import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "src/ui-treact/components/misc/Buttons.js";
import TeamIllustrationSrc from "src/ui-treact/images/team-illustration.svg";
// import {ReactComponent as SvgDotPattern } from "src/ui-treact/images/dot-pattern.svg"

const Container = tw.div`relative w-full`;
const TwoColumn = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto py-8 md:py-16 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`w-full flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-full mt-16 md:mt-0 mx-auto`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-black font-normal`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-3xl lg:text-4xl text-center md:text-left leading-tight text-black font-medium`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black font-normal`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  textOnLeft = true,
  data,
  page
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let finalData= data?.schema_json;  
  console.log('ImageWithText', finalData)
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={finalData.background_image} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{finalData.subHeading}</Subheading>
            <Heading>{finalData.heading}</Heading>
            <Description>{finalData.description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
